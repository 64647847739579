import { Dropdown, Menu, Space, Typography } from "antd";
import SNIcon from "components/SNIcon";
import SNTranslate from "components/SNTranslate";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const SNLanguage: FC = () => {
  const { i18n } = useTranslation();

  const locales = [
    {
      icon: "🇸🇪",
      value: "sv",
      label: "common.components.sn_language.swedish_item",
      onClick: () => {
        i18n.changeLanguage("sv");
        moment.locale("sv");
      },
    },
    {
      icon: "🇺🇸",
      value: "en",
      label: "common.components.sn_language.english_item",
      onClick: () => {
        i18n.changeLanguage("en");
        moment.locale("en");
      },
    },
  ];

  const menu = (
    <Menu>
      {locales.map((locale, index) => (
        <Menu.Item
          key={index}
          onClick={locale.onClick}
          style={{
            backgroundColor: locale.value === i18n.language ? "#42a5f5" : "#fff",
          }}
        >
          <Space size={[8, 0]}>
            <span role="img" aria-label={locale.icon}>
              {locale.icon}
            </span>
            <SNTranslate
              style={{ color: locale.value === i18n.language ? "#fff" : undefined }}
              text={locale.label}
            />
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Space size={[6, 0]}>
        <SNIcon
          icon={["fal", "globe-americas"]}
          size={"lg"}
          color="#FFF"
          style={{ cursor: "pointer" }}
        />
        <Title
          style={{
            marginBottom: 0,
            cursor: "pointer",
            color: "#FFF",
          }}
          level={5}
        >
          {locales.find((x) => x.value === i18n.language)?.value.toUpperCase()}
        </Title>
      </Space>
    </Dropdown>
  );
};

export default SNLanguage;
