import { Spin, SpinProps } from "antd";
import React, { FC } from "react";
import "./index.scss";

interface SNLoaderProps extends Omit<SpinProps, ""> {
  loaderStyle?: string;
  loading?: boolean;
  tip?: any;
  className?: string;
  isBlock?: any;
  height?: number;
  block?: boolean;
}

const SNLoader: FC<SNLoaderProps> = (props) => {
  const {
    loaderStyle,
    tip,
    className,
    isBlock,
    height,
    block,
    ...newProps
  } = props;

  if (loaderStyle === "dot") {
    return (
      <div
        className={`${className || ""} sn-spin-container ${
          isBlock ? "sn-loeader-center-block" : ""
        }`}
        style={{ height }}
      >
        <div className="sn-spin-chase">
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
        </div>
        <div className="sn-spin-tip">{tip}</div>
      </div>
    );
  }
  if (block) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
        }}
      >
        <Spin {...newProps} />
      </div>
    );
  }
  return <Spin {...newProps} />;
};

export default SNLoader;
