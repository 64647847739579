import { url } from "api/url";
import axios from "axios";
import { PublicInvoiceApi } from "models/publicInvoice";
import { useEffect, useState } from "react";

const useDetails = (
  id: string = "",
  initCall: boolean = true
): [PublicInvoiceApi | null, any, Function] => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const reload = () => {
    _fetch();
  };

  const _fetch = () => {
    axios
      .get(`${url.PUBLIC_INVOICE}${id}/`)
      .then((res) => {
        setData(res.data);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (initCall) {
      _fetch();
    }
  }, []);

  return [data, error, reload];
};

export const invoiceService = { useDetails };
