import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Modal, ModalProps, Space, Typography } from "antd";
import { imgs } from "assets/images";
import SNImage from "components/SNImage";
import { API_URL } from "configs/env";
import { PublicNodeApi } from "models/publicNode";
import React, { FC, useMemo } from "react";
import { formatAddress } from "utils/format";

const { Title, Link } = Typography;

interface SNOwnerInfoModalProps extends ModalProps {
  data: PublicNodeApi;
}

const SNOwnerInfoModal: FC<SNOwnerInfoModalProps> = (props) => {
  const {
    data: { created_for },
    ...rest
  } = props;

  const infoColumns: {
    icon: FontAwesomeIconProps["icon"];
    value: any;
    link: string;
  }[] = useMemo(() => {
    return [
      {
        icon: "phone",
        value: created_for.company_phone,
        link: `tel:${created_for.company_phone}`,
      },
      {
        icon: "envelope",
        value: created_for.company_email,
        link: `mailto:${created_for.company_email}`,
      },
      {
        icon: "map-marker-alt",
        value: formatAddress(created_for.address),
        link: `https://www.google.com/maps/search/?api=1&query=${created_for.address?.lat},${created_for.address?.lng}`,
      },
      {
        icon: "globe",
        value: created_for.website,
        link: `https://${created_for.website}`,
      },
    ];
  }, []);

  return (
    <Modal
      width={"350px"}
      bodyStyle={{ paddingTop: 5 }}
      footer={false}
      title={
        <Space align="center" size="middle">
          <SNImage
            src={`${API_URL}/${created_for.logo}`}
            size={50}
            image={
              !created_for.logo ? { med: imgs.companyPlaceholder } : undefined
            }
          />
          <span>
            <Title
              level={5}
              style={{
                marginBottom: 5,
              }}
            >
              {created_for.company_name}
            </Title>
          </span>
        </Space>
      }
      centered
      {...rest}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {infoColumns.map((col, index) => (
          <Space key={index} style={{ marginTop: 8 }} size="middle">
            <FontAwesomeIcon icon={col.icon} style={{ width: 20 }} />
            {col.value ? (
              <Link href={col.link} target="_blank">
                {col.value}
              </Link>
            ) : (
              "-"
            )}
          </Space>
        ))}
      </div>
    </Modal>
  );
};

export default SNOwnerInfoModal;
