import React from "react";
import SNLoader from "../SNLoader";
import Translate from "../SNTranslate";
import "./index.scss";
interface SNProcessLoaderProps {
  loading: boolean;
}

function SNProcessLoader(props: SNProcessLoaderProps) {
  const { loading } = props;
  return (
    <div
      className="sn-process-loader-container"
      style={{ display: loading ? "flex" : "none" }}
    >
      <SNLoader
        className="sn-process-spin"
        loaderStyle="dot"
        tip={<Translate text="common.components.sn_process_loader.default_loading" />}
      />
    </div>
  );
}

export default SNProcessLoader;
