import { Button, Checkbox, Form, FormInstance, Input, message, Select } from "antd";
import SNFormItem from "components/SNForm/SNFormItem";
import SNImagePicker from "components/SNImagePicker";
import SNPolicyModal from "components/SNPolicyModal";
import SNUserLocation from "components/SNUserLocation";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

const SNReportErrorForm: FC<{
  onFinish: (formData: any, form: FormInstance) => void;
}> = (props) => {
  const { onFinish } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [isPolicyVisible, setIsPolicyVisible] = useState(false);
  const [images, setImages] = useState<any[]>([]);

  const _handleFinishForm = (data: any) => {
    if (!data.is_accept_terms) {
      message.info(t("common.components.sn_error_report_form.form_term_validation"));
    } else {
      onFinish({ ...data, ...{ images } }, form);
    }
  };

  const _openPolicy = () => {
    setIsPolicyVisible(true);
  };

  return (
    <Form
      layout="vertical"
      style={{ marginLeft: 15, marginRight: 15 }}
      onFinish={_handleFinishForm}
      form={form}
      initialValues={{
        type: 3,
        is_accept_terms: false,
        is_contact: false,
        location_option: 1,
      }}
    >
      <SNFormItem
        label={"common.components.sn_error_report_form.form_description_label"}
        required
        name={"description"}
      >
        <Input.TextArea
          placeholder={t("common.components.sn_error_report_form.form_description_placeholder")}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </SNFormItem>

      <SNFormItem label={"common.components.sn_error_report_form.form_type_label"} name={"type"}>
        <Select
          options={[
            { label: t("system.error.type.uncategorized"), value: 3 },
            { label: t("system.error.type.service_required"), value: 4 },
            { label: t("system.error.type.refilling_required"), value: 5 },
            { label: t("system.error.type.cleaning_required"), value: 1 },
            { label: t("system.error.type.partial_faulty"), value: 6 },
            { label: t("system.error.type.out_of_order"), value: 2 },
          ]}
          placeholder={t("common.components.sn_error_report_form.form_type_placeholder")}
          allowClear={false}
        />
      </SNFormItem>

      <SNUserLocation
        name="location_option"
        label={"common.components.sn_error_report_form.form_location_label"}
        form={form}
      />

      <SNFormItem
        label={"common.components.sn_error_report_form.form_email_label"}
        required
        name={"email"}
        rules={[{ type: "email" }]}
      >
        <Input placeholder={t("common.components.sn_error_report_form.form_email_placeholder")} />
      </SNFormItem>

      <SNFormItem
        label={"common.components.sn_error_report_form.form_first_name_label"}
        required
        name={"first_name"}
      >
        <Input
          placeholder={t("common.components.sn_error_report_form.form_first_name_placeholder")}
        />
      </SNFormItem>

      <SNFormItem
        label={"common.components.sn_error_report_form.form_last_name_label"}
        name={"last_name"}
      >
        <Input
          placeholder={t("common.components.sn_error_report_form.form_last_name_placeholder")}
        />
      </SNFormItem>

      <SNFormItem label={"common.components.sn_error_report_form.form_phone_label"} name={"phone"}>
        <Input placeholder={t("common.components.sn_error_report_form.form_phone_placeholder")} />
      </SNFormItem>

      <SNFormItem label={"common.components.sn_error_report_form.form_images_label"}>
        <SNImagePicker onChange={setImages} />
      </SNFormItem>

      <SNFormItem name={"is_contact"} valuePropName="checked" style={{ marginTop: 10 }}>
        <Checkbox>{t("common.components.sn_error_report_form.form_contact_me_label")}</Checkbox>
      </SNFormItem>

      <SNFormItem name={"is_accept_terms"} valuePropName="checked">
        <Checkbox>
          <span>
            {t("common.components.sn_error_report_form.form_accept_temps_label")}
            <Button type="link" style={{ marginLeft: -16 }} onClick={_openPolicy}>
              {t("common.components.sn_error_report_form.data_policy_btn_label")}
            </Button>
          </span>
        </Checkbox>
      </SNFormItem>

      <SNPolicyModal
        visible={isPolicyVisible}
        onOk={() => setIsPolicyVisible(false)}
        onCancel={() => setIsPolicyVisible(false)}
      />

      <Button
        block
        type="primary"
        style={{ marginTop: 20, marginBottom: 15 }}
        onClick={form.submit}
      >
        {t("common.components.sn_error_report_form.submit_btn_label")}
      </Button>
    </Form>
  );
};

export default SNReportErrorForm;
