import { IconName } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, List, Row } from "antd";
import Title from "antd/lib/typography/Title";
import SNEmpty from "components/SNEmpty";
import SNHeader from "components/SNHeader";
import SNImage from "components/SNImage";
import SNLayout from "components/SNLayout";
import { PublicNodeApi } from "models/publicNode";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "redux/reducers";
import { setAssetID, setPlaceID } from "redux/reducers/nodeReducer";
import "./index.scss";

const QRScan = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { nodeID, nodeData } = useSelector((state: RootState) => state.node);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!nodeData) return null;
  const { assets, node_id, places } = nodeData as PublicNodeApi;
  const combineList = [
    ...assets.map((item) => {
      return { ...item, item_type: "asset" };
    }),
    ...places.map((item) => {
      return { ...item, item_type: "place" };
    }),
  ];
  const _renderEmpty = (icon: IconName, message: string) => {
    return (
      <SNEmpty
        image={
          // @ts-ignore
          <FontAwesomeIcon icon={["fal", icon]} size={"5x"} color={"#bfbfbf"} />
        }
        extraHeight={50}
        title={message}
      />
    );
  };

  const _renderPlaceItem = (item: any, index: any) => {
    return (
      <div
        key={index}
        onClick={() => {
          dispatch(setPlaceID(item.id));
          history.push(`/${nodeID}/place`);
        }}
        style={{
          backgroundColor: "#FFF",
          cursor: "pointer",
          paddingTop: 10,
        }}
      >
        <Row wrap={false}>
          <Col
            flex={"100px"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <SNImage
              preview={false}
              size={80}
              src={item.images[0]?.image.med || ""}
              icon={
                <FontAwesomeIcon icon={["fal", "map-marked-alt"]} size={"xs"} />
              }
              style={{ paddingLeft: 10 }}
            />
          </Col>
          <Col flex="auto">
            <Row align="middle" style={{ height: 80 }}>
              <Col span={21}>
                <Title level={5} style={{ marginTop: 5 }}>
                  {item.name}
                </Title>
              </Col>
              <Col
                span={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FontAwesomeIcon
                  icon={"chevron-right"}
                  color={"#42a5f5"}
                  size={"lg"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: "10px 0 0 0" }} />
      </div>
    );
  };

  const _renderAssetItem = (item: any, index: any) => {
    return (
      <div
        key={index}
        onClick={() => {
          dispatch(setAssetID(item.id));
          history.push(`/${nodeID}/asset`);
        }}
        style={{
          backgroundColor: "#FFF",
          cursor: "pointer",
          paddingTop: 10,
        }}
      >
        <Row wrap={false}>
          <Col
            flex={"100px"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <SNImage
              preview={false}
              size={80}
              src={item.images[0]?.image.med || ""}
              icon={<FontAwesomeIcon icon={["fal", "cube"]} size={"sm"} />}
              style={{ paddingLeft: 10 }}
            />
          </Col>
          <Col flex="auto">
            <Row align="middle" style={{ height: 80 }}>
              <Col span={21}>
                <Title level={5} style={{ marginTop: 5 }}>
                  {item.title}
                </Title>
              </Col>
              <Col
                span={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FontAwesomeIcon
                  icon={"chevron-right"}
                  color={"#42a5f5"}
                  size={"lg"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: "10px 0 0 0" }} />
      </div>
    );
  };

  const _renderQRItem = (item: any, index: number) => {
    if (item.item_type === "asset") {
      return _renderAssetItem(item, index);
    } else {
      return _renderPlaceItem(item, index);
    }
  };

  return (
    <SNLayout>
      <div style={{ height: "auto", backgroundColor: "#F8F9FF" }}>
        <SNHeader title={`${node_id}`} />
        <div>
          {combineList?.length ? (
            <List
              style={{
                minHeight: "calc(100vh - 45px)",
                backgroundColor: "#F8F9FF",
              }}
              itemLayout="vertical"
              dataSource={combineList}
              renderItem={_renderQRItem}
            />
          ) : (
            _renderEmpty("cube", t("pages.qr_scan.list_empty"))
          )}
        </div>
      </div>
    </SNLayout>
  );
};

export default QRScan;
