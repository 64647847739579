import logoIcon from "./icon.svg";
import companyPlaceholder from "./img-placeholder.jpg";
import logo from "./logo.svg";
import logoWhite from "./logo_white.svg";

export const imgs = {
  logo,
  logoWhite,
  logoIcon,
  companyPlaceholder,
};
