import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface NodeState {
  nodeData: any;
  nodeID?: string;
  assetID?: string;
  placeID?: string;
}

let initialState: NodeState = {
  nodeData: null,
  nodeID: "",
  assetID: "",
  placeID: "",
};

const nodeReducer = createSlice({
  name: "node",
  initialState,
  reducers: {
    setNodeData: (state, action: PayloadAction<any>) => {
      state.nodeData = action.payload;
    },
    setNodeID: (state, action: PayloadAction<string>) => {
      state.nodeID = action.payload;
    },
    setAssetID: (state, action: PayloadAction<string>) => {
      state.assetID = action.payload;
    },
    setPlaceID: (state, action: PayloadAction<string>) => {
      state.placeID = action.payload;
    },
  },
});

export default nodeReducer.reducer;

export const { setNodeData, setNodeID, setAssetID, setPlaceID } =
  nodeReducer.actions;

export const selectCurrentAsset = createSelector(
  (state: RootState) => {
    return state.node;
  },
  ({ nodeData, assetID }) => {
    const assets = nodeData?.assets;
    if (!assets || !assets.length) return null;

    return assets.find((x: any) => x.id === assetID);
  }
);

export const selectCurrentPlace = createSelector(
  (state: RootState) => {
    return state.node;
  },
  ({ nodeData, placeID }) => {
    const places = nodeData?.places;
    if (!places || !places.length) return null;
    
    return places.find((x: any) => x.id === placeID);
  }
);
