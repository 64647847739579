import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export const getStore = () => {
  return store;
};

export default store;
