export default (
  <div>
    <p>
      In order for you to report an error, you must first state your (i)
      firstname, surname and (ii) e-mail address, hereinafter collectively 
      referred to as the user data.
    </p>

    <p>
      It is completely voluntary to provide the user information, but these are
      necessary for you to be able to make a error report.
    </p>

    <p>
      Service Node is responsible for the user information. The user information
      is collected and processed solely for the purpose of being able to register
      and administer your error report.
    </p>

    <p>
      To resolve any errors and to provide feedback to you the user information
      may be disclosed to Service Node's customers and / or subcontractors and
      consultants.
    </p>

    <p>
      In addition, Service Node will not disclose personal information to
      any additional external party except in cases where:
    </p>

    <ol type="i">
      <li>it has been specifically agreed between Service Node and you</li>

      <li>
        as it is necessary within the framework of a certain assignment to
        protect your rights
      </li>

      <li>
       if it is necessary for the Service Node to comply with statutory requirements
       obligation or comply with government decisions or court decisions
      </li>

      <li>
        in the event that Service Node engages an external service provider
        who performs assignments on behalf of the company. These service providers are then
        obliged to use only your personal data for Service Nodes
        account and take neccessary measures to protect them. Personal data will not be
        disclosed to third parties for marketing purposes. The user information
        may be carried outside the EU / EEA.
      </li>
    </ol>

    <p>
      You can at any time revoke your consent that allows Service Node to handle
      your user information. This has to be done in writing either by e-mail or letter.
    </p>

    <p>
      You have the right to submit a written application to Service Node and free of charge
      get information about which personal data the company processes and how these
      are managed. You also have the right to request correction in respect of
      personal data that Service Node processes about you and request correction of
      these. Any request for such correction shall be made in writing
      to Service Node. Your personal data will not be processed for
      longer than necessary.
    </p>

    <p>
      By accepting these terms, you agree to the treatment of
      The user information listed above.
    </p>

    <p>
      Inquiries and information about our processing of personal data are provided
      of on request by contact on{" "}
      <a href="mailto:support@servicenode.se" className="link">
        support@servicenode.se
      </a>
    </p>

    <p>    
      For further information on current legislation, what responsibility Service
      Node has and what rights you have, see <br />
      <a
        href="http://www.datainspektionen.se/dataskyddsreformen/"
        className="link"
      >
        http://www.datainspektionen.se/dataskyddsreformen/
      </a>
    </p>
  </div>
);