import { UploadOutlined } from "@ant-design/icons";
import { FormInstance, Space, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload/interface";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface SNImagePickerProps {
  form?: FormInstance<any>;
  name?: string;
  maxLength?: number;
  onChange: (files: any) => void;
}

const SNImagePicker: FC<SNImagePickerProps> = (props) => {
  const { maxLength = 5, onChange } = props;
  const { t } = useTranslation();

  const [files, setFiles] = useState<any[]>([]);

  const _onChange = ({ fileList }: UploadChangeParam) => {
    setFiles(fileList);
    onChange && onChange(fileList);
  };

  const _onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const fakeRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Upload
      listType="picture-card"
      fileList={files}
      onChange={_onChange}
      onPreview={_onPreview}
      onRemove={() => true}
      customRequest={fakeRequest}
      accept="image/gif,image/jpeg,image/jpg,image/png"
      beforeUpload={() => {
        return false;
      }}
    >
      {files.length < maxLength && (
        <Space>
          <UploadOutlined />
          {t("common.components.sn_image_picker.upload_btn_label")}
        </Space>
      )}
    </Upload>
  );
};

export default SNImagePicker;
