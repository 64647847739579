import { Modal, ModalProps } from "antd";
import ENPolicy from "configs/locales/policy.en";
import SVPolicy from "configs/locales/policy.sv";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface SNPolicyModalProps extends ModalProps {}

const SNPolicyModal: FC<SNPolicyModalProps> = (props) => {
  const { i18n, t } = useTranslation();

  return (
    <Modal
      title={t("common.components.sn_policy_modal.header_title")}
      centered
      okButtonProps={{ style: { display: "none" } }}
      width={"500px"}
      bodyStyle={{ height: "60vh" }}
      {...props}
    >
      <div style={{ height: "55vh", overflow: "scroll", width: "100%" }}>
        {i18n.language === "en" ? ENPolicy : SVPolicy}
      </div>
    </Modal>
  );
};

export default SNPolicyModal;
