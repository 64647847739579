const PDF_ICON = "file-pdf";
const IMAGE_ICON = "file-image";
const DOC_ICON = "file-word";
const EXCEL_ICON = "file-excel";
const PPT_ICON = "file-powerpoint";
const OTHER_FILE_ICON = "file-alt";

const pdfRegex = /\.(pdf)$/i;
const excelRegex = /\.(csv|xlsx|xls|slt|xlm|xlsm|xltx|xltm)$/i;
const imageRegex = /\.(gif|jpg|jpeg|tiff|png)$/i;
const docRegex = /\.(docx|doc|docm|dotx|dotm)$/i;
const pptRegex = /\.(ppt|pptx)$/i;

export const getFileIcon = (file: string) => {
  if (pdfRegex.test(file)) {
    return PDF_ICON;
  } else if (imageRegex.test(file)) {
    return IMAGE_ICON;
  } else if (docRegex.test(file)) {
    return DOC_ICON;
  } else if (excelRegex.test(file)) {
    return EXCEL_ICON;
  } else if (pptRegex.test(file)) {
    return PPT_ICON;
  } else {
    return OTHER_FILE_ICON;
  }
};
