import React, { FC } from "react";
import "./index.scss";

interface SNLayoutProps {
  maxWidth?: number;
}

const SNLayout: FC<SNLayoutProps> = (props) => {
  const { maxWidth } = props;

  return (
    <div className="sn-layout-root">
      <div className="sn-single-screen">
        <div className="sn-body-relative" style={{ maxWidth }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SNLayout;
