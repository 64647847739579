import _ from "lodash";

export const cleanObject = (obj: any): any => {
  let newObj: any = [];
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] || obj[prop] === "0" || obj[prop] === 0) {
      newObj.push(obj[prop]);
    }
  });
  return newObj;
};
