import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import translationEn from "../configs/locales/en.json";
import translationSv from "../configs/locales/sv.json";

const languages = ["en", "sv"];

const resource = {
  en: {
    translation: translationEn,
  },
  sv: {
    translation: translationSv,
  },
};

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["path", "navigator"] },
    fallbackLng: "sv",
    resources: resource,
    whitelist: languages,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    parseMissingKeyHandler: (key: string) => `Add trans> ${key}`,
  });

export default i18n;
