export const PARTS_UNIT = [
  {
    value: "pcs",
    shortLabel: "system.part.unit_short.pieces",
  },
  {
    value: "hrs",
    shortLabel: "system.part.unit_short.hours",
  },
  {
    value: "min",
    shortLabel: "system.part.unit_short.minutes",
  },
  {
    value: "g",
    shortLabel: "system.part.unit_short.gram",
  },
  {
    value: "hg",
    shortLabel: "system.part.unit_short.hectogram",
  },
  {
    value: "kg",
    shortLabel: "system.part.unit_short.kilogram",
  },
  {
    value: "lbs",
    shortLabel: "system.part.unit_short.pound",
  },
  {
    value: "ton",
    shortLabel: "system.part.unit_short.ton",
  },
  {
    value: "mm",
    shortLabel: "system.part.unit_short.millimeter",
  },
  {
    value: "cm",
    shortLabel: "system.part.unit_short.centimeter",
  },
  {
    value: "dm",
    shortLabel: "system.part.unit_short.decimeter",
  },
  {
    value: "m",
    shortLabel: "system.part.unit_short.meter",
  },
  {
    value: "inch",
    shortLabel: "system.part.unit_short.inch",
  },
  {
    value: "ft",
    shortLabel: "system.part.unit_short.foot",
  },
  {
    value: "m2",
    shortLabel: "system.part.unit_short.square_meter",
  },
  {
    value: "m3",
    shortLabel: "system.part.unit_short.cubic_meter",
  },
  {
    value: "cl",
    shortLabel: "system.part.unit_short.centiliter",
  },
  {
    value: "ml",
    shortLabel: "system.part.unit_short.milliliter",
  },
  {
    value: "dl",
    shortLabel: "system.part.unit_short.deciliter",
  },
  {
    value: "l",
    shortLabel: "system.part.unit_short.liter",
  },
];
