import { Result } from "antd";
import SNProcessLoader from "components/SNProcessLoader";
import { ENV_NAME } from "configs/env";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { RootState } from "redux/reducers";
import { setNodeID } from "redux/reducers/nodeReducer";
import AssetOverview from "screens/AssetOverview";
import Invoice from "screens/Invoice";
import PlaceOverview from "screens/PlaceOverview";
import QRScan from "screens/QRScan";
import { appService } from "./service";

const RootRouter = () => {
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { isLoading } = useSelector((state: RootState) => state.loader);
  const dispatch = useDispatch();

  const isPublicNode = !pathname.includes("invoice");

  const nodeID = useMemo(() => {
    return pathname.split("/").filter((data) => !!data)[0];
  }, [pathname]);

  useEffect(() => {
    if (isPublicNode) {
      dispatch(setNodeID(nodeID));
    }
  }, [nodeID]);

  const [node, error] = appService.useNodeDetails(nodeID, isPublicNode);

  if (isPublicNode) {
    document.title = nodeID
      ? `${nodeID} | ${
          ENV_NAME !== "Production" ? `${_.capitalize(ENV_NAME)} -` : ""
        } Public Service Node`
      : "Ingen Servicenod Hittad!";

    if (!node && !error) {
      return <SNProcessLoader loading={true} />;
    } else if (error) {
      return (
        <Result
          status="500"
          title="500"
          subTitle={t("common.page_not_found")}
        />
      );
    }
  } else {
    document.title = "Invoice - Service Node";
  }

  return (
    <div>
      <Switch>
        <Route
          exact
          path={[`/${nodeID}`]}
          component={(props: any) => <QRScan {...props} />}
        />
        <Route
          exact
          path={`/${nodeID}/asset`}
          component={(props: any) => <AssetOverview {...props} />}
        />
        <Route
          exact
          path={`/${nodeID}/place`}
          component={(props: any) => <PlaceOverview {...props} />}
        />
        <Route
          exact
          path={`/invoice/:id`}
          component={(props: any) => <Invoice {...props} />}
        />
        <Route
          render={() => (
            <Result
              status="500"
              title="500"
              subTitle={t("common.page_not_found")}
            />
          )}
        />
      </Switch>
      <SNProcessLoader loading={isLoading} />
    </div>
  );
};

export default RootRouter;
