import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoaderState {
  isLoading: boolean;
}

let initialState: LoaderState = {
  isLoading: false,
};

const loaderReducer = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default loaderReducer.reducer;

export const { setLoading } = loaderReducer.actions;
