import {
  ExclamationCircleOutlined,
  FileTextOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  FormInstance,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import SNDocumentsViewList from "components/SNDocumentsViewList";
import SNHeader from "components/SNHeader";
import SNImage from "components/SNImage";
import SNLayout from "components/SNLayout";
import SNReportErrorForm from "components/SNReportErrorForm";
import SNServiceViewList from "components/SNServiceViewList";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "redux/reducers";
import { setLoading } from "redux/reducers/loaderReducer";
import { selectCurrentPlace } from "redux/reducers/nodeReducer";
import { appService } from "screens/App/service";
import { getAddressObj } from "utils/format";

const { TabPane } = Tabs;
const { Text } = Typography;

const PlaceOverview: FC = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { nodeID } = useSelector((state: RootState) => state.node);
  const { location } = useSelector((state: RootState) => state.userSetting);

  const [data, error, reload] = appService.useNodeDetails(nodeID, false);
  const placeDetails = useSelector(selectCurrentPlace);

  //Handler

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _handleReport = async (data: any, form: FormInstance<any>) => {
    const {
      type,
      description,
      email,
      first_name,
      last_name,
      is_contact,
      phone,
      images,
      location_option,
    } = data;
    dispatch(setLoading(true));

    let formData = new FormData();

    if (location && location.lat && location_option === 1) {
      const user_location = await appService.getUserLocation(
        location?.lat,
        location?.lng
      );
      formData.append(
        "location",
        JSON.stringify(getAddressObj(user_location.data.results[0]))
      );
    }

    formData.append("place", placeDetails.id);
    formData.append("type", type);
    formData.append("report_date", moment().format("YYYY-MM-DDTHH:mm"));
    formData.append("description", description);
    formData.append("is_notify_reporter", is_contact);
    formData.append("language", i18n.language);
    formData.append(
      "reported_by_external_user",
      JSON.stringify({
        first_name,
        last_name,
        contact: {
          email,
          phone1: phone,
        },
      })
    );

    if (images.length) {
      for (var f of images) {
        formData.append("files", f.originFileObj);
      }
    }

    appService
      .createError(formData)
      .then((res) => {
        reload && reload();

        dispatch(setLoading(false));
        Modal.success({
          title: t(
            "common.components.sn_error_report_form.success_modal_header"
          ),
          content: t(
            "common.components.sn_error_report_form.success_modal_body"
          ),
          centered: true,
        });
        form.resetFields();
        window.scroll(0, 0);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        message.error(
          t("common.components.sn_error_report_form.report_failed")
        );
      });
  };

  // Validate component
  if (!placeDetails) {
    return <Redirect push to={`/${nodeID}`} />;
  }

  const { name, services, documents, images, statistics } = placeDetails;

  const renderTabs = () => {
    const tabOptions = [
      {
        label: t("pages.place_overview.tabs.report_error_label"),
        icon: <ExclamationCircleOutlined style={{ fontSize: "17px" }} />,
        render: <SNReportErrorForm onFinish={_handleReport} />,
      },
      {
        label: t("pages.place_overview.tabs.documents_label"),
        icon: <FileTextOutlined style={{ fontSize: "17px" }} />,
        render: (
          <SNDocumentsViewList
            data={documents}
            emptyMessage={t("pages.place_overview.documents.list_empty")}
          />
        ),
      },
      {
        label: t("pages.place_overview.tabs.service_history_label"),
        icon: <HistoryOutlined style={{ fontSize: "17px" }} />,
        render: (
          <SNServiceViewList
            data={services}
            emptyMessage={t("pages.place_overview.service_history.list_empty")}
          />
        ),
      },
    ];

    return (
      <Tabs centered tabBarStyle={{ marginBottom: 16 }}>
        {tabOptions.map((tab, index) => (
          <TabPane
            tab={
              <span>
                {tab.icon}
                {tab.label}
              </span>
            }
            key={`Tab-${index}`}
          >
            {tab.render}
          </TabPane>
        ))}
      </Tabs>
    );
  };

  const renderPlaceInfo = () => {
    return (
      <div style={{ padding: 15 }}>
        <Row wrap={false}>
          <Col flex="100px">
            <SNImage
              size={80}
              src={images[0]?.image.med || ""}
              icon={
                <FontAwesomeIcon icon={["fal", "map-marked-alt"]} size={"xs"} />
              }
            />
          </Col>
          <Col flex="auto">
            <div
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Text strong>{name}</Text>

              <Space>
                <FontAwesomeIcon
                  icon={"exclamation-triangle"}
                  color={"#f50"}
                  size={"sm"}
                />
                <Text style={{ fontSize: 14 }}>
                  {`${t("pages.place_overview.active_error_label")}: `}
                  <Text
                    style={{
                      color:
                        statistics.error_active_total === 0
                          ? "#42a5f5"
                          : "#f50",
                    }}
                  >
                    {statistics.error_active_total === 0
                      ? t("pages.place_overview.no_active_error")
                      : statistics.error_active_total}
                  </Text>
                </Text>
              </Space>
              <Space>
                <FontAwesomeIcon icon={"tools"} color={"#42a5f5"} size={"sm"} />
                <Text style={{ fontSize: 14 }}>
                  {`${t("pages.place_overview.last_service_label")}: `}
                  <Text style={{ color: "#42a5f5" }}>
                    {services[0]
                      ? moment(services[0].service_date).format("YYYY-MM-DD")
                      : t("pages.place_overview.last_service_empty")}
                  </Text>
                </Text>
              </Space>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <SNLayout>
      <SNHeader title={t("pages.place_overview.header_title")} back />
      {renderPlaceInfo()}
      {renderTabs()}
    </SNLayout>
  );
};

export default PlaceOverview;
