import { GoogleMap, MarkerF } from "@react-google-maps/api";

import { Select } from "antd";
import SNFormItem from "components/SNForm/SNFormItem";
import { MAP_CENTER } from "constants/map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setLocation } from "redux/reducers/userSettingReducer";

const SNUserLocation = (props: any) => {
  const { name, label, form } = props;
  const [map, setMap] = useState<any>(null);
  const { location } = useSelector((state: RootState) => state.userSetting);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [locationOption, setLocationOption] = useState(form?.getFieldValue(name) || 1);
  const [geoPermission, setGeoPermission] = useState<PermissionState | null>(null);

  const options = useMemo(() => {
    let originOptions = [
      { label: t("common.components.sn_user_location.user_location_btn_label"), value: 1 },
      { label: t("common.components.sn_user_location.remove_location_btn_label"), value: 2 },
    ];

    if (geoPermission === "denied") {
      originOptions = originOptions.filter((x) => x.value !== 1);
    }

    return originOptions;
  }, [geoPermission, i18n.languages]);

  const panToLocation = (mapRef: any, location: any, timeout = 0) => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(location);
    mapRef?.fitBounds(bounds);
    if (timeout > 0) {
      setTimeout(() => {
        mapRef?.setZoom(18);
      }, timeout);
    } else {
      mapRef?.setZoom(18);
    }
  };

  useEffect(() => {
    if (location?.lat) {
      panToLocation(map, location, 500);
    }
  }, [location]);

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator?.permissions.query({ name: "geolocation" }).then(function (result) {
        setGeoPermission(result.state);
        result.onchange = function () {
          setGeoPermission(result.state);
        };
      });
    }
  }, [navigator]);

  const onLoad = useCallback((map) => {
    panToLocation(map, MAP_CENTER);
    setMap(map);

    navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
      const pos = { lat, lng };
      dispatch(setLocation(pos));
    });
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return (
    <>
      <SNFormItem name={name} label={label}>
        <Select
          options={options}
          placeholder={t("common.components.sn_user_location.placeholder")}
          allowClear={false}
          defaultValue={locationOption}
          onChange={setLocationOption}
        />
      </SNFormItem>


      {locationOption === 1 && (
        // @ts-ignore
        <GoogleMap
          mapContainerStyle={{ height: "200px", marginTop: "10px", marginBottom: "10px" }}
          mapContainerClassName="sn-border"
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {location && <MarkerF position={location} animation={window.google.maps.Animation.DROP} />}
        </GoogleMap>
      )}
    </>
  );
};

export default SNUserLocation;
