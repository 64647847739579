import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, Space, Tag, Typography } from "antd";
import SNEmpty from "components/SNEmpty";
import { PublicNodeServiceApi } from "models/publicNode";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const { Text, Paragraph } = Typography;

const SNServiceViewList: FC<{
  data: PublicNodeServiceApi[];
  emptyMessage?: string;
}> = (props) => {
  const { t } = useTranslation();
  const { data, emptyMessage = t("common.components.sn_service_view_list.list_empty") || "" } =
    props;

  if (!data || !data.length) {
    return (
      <SNEmpty extraHeight={200} title={emptyMessage} containerStyle={{ paddingBottom: 100 }} />
    );
  }

  return (
    <div>
      <List
        style={{
          minHeight: "calc(100vh - 200px)",
        }}
        itemLayout="vertical"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            key={index}
            style={{ padding: 15 }}
            extra={
              <div className="ant-list-vertical ant-list-item-extra">
                <Tag color="blue">{moment(item.service_date).format("YYYY-MM-DD")}</Tag>
              </div>
            }
          >
            <List.Item.Meta
              title={<Text strong>{item.type}</Text>}
              description={
                <div>
                  <Space>
                    <FontAwesomeIcon icon={"building"} />
                    <Text type="secondary">{item.service_company}</Text>
                  </Space>
                </div>
              }
            />
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: t("common.components.sn_service_view_list.more_btn_label"),
              }}
            >
              {item.description}
            </Paragraph>
          </List.Item>
        )}
      />
    </div>
  );
};

export default SNServiceViewList;
