import {
  FontAwesomeIcon as Icon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

interface SNIconProps extends Omit<FontAwesomeIconProps, "snIcon"> {}

const SNIcon = ({
  className,
  color,
  style,
  icon,
  name,
  ...props
}: SNIconProps) => {
  if (!icon) return null;

  return (
    <Icon
      {...props}
      icon={icon}
      aria-hidden="true"
      style={{ color: color, ...style }}
      className={`sn-icon ${className || ""}`}
    />
  );
};

export default SNIcon;
