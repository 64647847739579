import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import nodeReducer from "./nodeReducer";
import userSettingReducer from "./userSettingReducer";

export const rootReducer = combineReducers({
  node: nodeReducer,
  loader: loaderReducer,
  userSetting: userSettingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
