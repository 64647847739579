export const formatSizeUnit = (bytes: number) => {
  if (bytes >= 1073741824) {
    return (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    return (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    return bytes + " bytes";
  } else if (bytes === 1) {
    return bytes + " byte";
  } else {
    return "0 bytes";
  }
};

export function formatAddress(address: any = {}): string {
  const city = address && address.city ? address.city : "";
  const street = address && address.address ? address.address : "";
  const post = address && address.post ? address.post : "";

  if (!city.length) return "";
  else
    return `${street.length ? `${street},` : ""} ${post.length ? `${post},` : ""} ${city}`.trim();
}

/**
 *
 * @param address : Google map api results
 * @returns locationObj
 */
export function getAddressObj(address: any) {
  if (!address) return null;

  let locationObj = {
    address: "",
    post: "",
    city: "",
    country: "",
    lat: address.geometry.location.lat,
    lng: address.geometry.location.lng,
  };

  for (const component of address.address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        locationObj.address = `${component.long_name} `;
        break;
      }

      case "route": {
        locationObj.address += component.short_name;
        break;
      }

      case "locality":
        locationObj.address += `, ${component.short_name}`;
        break;

      case "administrative_area_level_1": {
        locationObj.city = component.short_name;
        break;
      }

      case "postal_code": {
        locationObj.post = component.short_name;
        break;
      }

      case "country":
        locationObj.country = component.long_name;
        break;
    }
  }

  return locationObj;
}
