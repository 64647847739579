import axios from "axios";
import { GOOGLE_MAP_KEY } from "configs/env";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNodeData } from "../../redux/reducers/nodeReducer";
import { url } from "./../../api/url";

const useNodeDetails = (nodeID: string = "", initCall: boolean = true) => {
  const [node, setNode] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const reload = () => {
    _fetch();
  };

  const _fetch = () => {
    axios
      .get(`${url.PUBLIC_NODE_SCAN}${nodeID}/`)
      .then((res) => {
        setNode(res.data);
        dispatch(setNodeData(res.data));
        setError(null);
      })
      .catch((err) => {
        setError(err);
        dispatch(setNodeData(null));
        setNode(null);
      });
  };

  useEffect(() => {
    if (initCall) {
      _fetch();
    }
  }, []);

  return [node, error, reload];
};

const createError = (params: any) => {
  return axios.post(url["PUBLIC_ERROR"], params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const getUserLocation = async (lat: any, lng: any) => {
  const googleMapUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_KEY}`;

  return axios.get(googleMapUrl);
};

export const appService = { useNodeDetails, createError, getUserLocation };
