import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MAP_CENTER } from "constants/map";
interface UserSettingState {
  location: any;
}

let initialState: UserSettingState = {
  location: null,
};

const userSettingReducer = createSlice({
  name: "userSetting",
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<any>) => {
      state.location = action.payload;
    },
  },
});

export default userSettingReducer.reducer;

export const { setLocation } = userSettingReducer.actions;
