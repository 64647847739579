import { EyeOutlined } from "@ant-design/icons";
import { Avatar, Image, ImageProps } from "antd";
import React, { CSSProperties, FC, ReactNode } from "react";
import "./index.scss";
export interface ImageData {
  low?: string;
  med?: string;
  full?: string;
  created_at?: string;
  title?: string;
  id?: number;
}

interface previewType {
  visible?: boolean;
  onVisibleChange?: (visible: boolean, prevVisible: boolean) => void;
  getContainer?: string | HTMLElement | (() => HTMLElement); // V4.8.0
  src?: string; // V4.10.0
  mask?: ReactNode; // V4.9.0
  maskClassName?: string; // V4.11.0
}
interface SNImageProps extends ImageProps {
  alt?: string;
  preview?: boolean;
  src?: string;
  width?: string | number;
  height?: string | number;
  fallback?: string;
  className?: string;
  image?: ImageData;
  size?: any;
  style?: CSSProperties;
  noBoder?: boolean;
  icon?: any;
  actions?: ReactNode;
}

const SNImage: FC<SNImageProps> = (props) => {
  const {
    className,
    image,
    size = 60,
    noBoder = false,
    preview = true,
    width,
    height,
    src,
    icon,
    actions,
    ...newProps
  } = props;

  if (!image && !src && icon)
    return (
      <Avatar
        size={size}
        icon={icon}
        shape="square"
        className="sn-entity-image-icon"
      />
    );

  const preViewProps: previewType = {
    mask: actions ? (
      <span className="ant-upload-list-item-actions">{actions}</span>
    ) : (
      <EyeOutlined />
    ),
    src: image?.full || image?.med || image?.low || props.src,
  };
  if (image)
    return (
      <Image
        {...newProps}
        preview={preview ? preViewProps : false}
        width={width || size}
        height={height || size}
        wrapperClassName={`sn-image ${!noBoder && "sn-border"} ${
          className || ""
        }`}
        src={image.low || image.med || image.full}
        placeholder={
          <Image
            {...newProps}
            src={image.low || image.med || image.full}
            preview={preview ? preViewProps : false}
            height={height || size}
            className={`sn-image ${!noBoder && "sn-border"} ${className || ""}`}
            width={width || size}
          />
        }
      />
    );
  return (
    <Image
      {...newProps}
      style={{ objectFit: "cover" }}
      src={src}
      preview={preview ? preViewProps : false}
      width={width || size}
      height={height || size}
      wrapperClassName={`sn-image ${!noBoder && "sn-border"} ${
        className || ""
      }`}
    />
  );
};

export default SNImage;
