import { API_URL, API_VERSION } from "../configs/env";

const PUBLIC_NODE_SCAN = `${API_URL}/${API_VERSION}/public/scan/`;
const PUBLIC_ERROR = `${API_URL}/${API_VERSION}/public/error/`;
const PUBLIC_INVOICE = `${API_URL}/${API_VERSION}/public/invoice/`;

export const url = {
  PUBLIC_NODE_SCAN,
  PUBLIC_ERROR,
  PUBLIC_INVOICE,
};
