import { List, Typography } from "antd";
import SNEmpty from "components/SNEmpty";
import SNIcon from "components/SNIcon";
import { getFileIcon } from "constants/document";
import { PublicNodeDocApi } from "models/publicNode";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatSizeUnit } from "utils/format";

const { Text } = Typography;

const SNDocumentsViewList: FC<{
  data: PublicNodeDocApi[];
  emptyMessage?: string;
}> = (props) => {
  const { t } = useTranslation();
  const { data, emptyMessage = t("common.components.sn_document_view_list.list_empty") || "" } =
    props;

  if (!data || !data.length) {
    return (
      <SNEmpty extraHeight={200} title={emptyMessage} containerStyle={{ paddingBottom: 100 }} />
    );
  }

  const _handleDocClick = (file: string) => {
    window.open(`${file}`, "_blank");
  };

  return (
    <div>
      <List
        style={{
          minHeight: "calc(100vh - 200px)",
        }}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Text style={{ color: "#42a5f5" }}>
                {t("common.components.sn_document_view_list.list_item_open")}
              </Text>,
            ]}
            style={{ paddingLeft: 15, paddingRight: 15, cursor: "pointer" }}
            onClick={() => _handleDocClick(item.document.file)}
          >
            <List.Item.Meta
              avatar={
                <SNIcon icon={getFileIcon(item.document.file)} size={"3x"} color={"#42a5f5"} />
              }
              title={<a>{item.document.title}</a>}
              description={formatSizeUnit(item.document.size)}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default SNDocumentsViewList;
