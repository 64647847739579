import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfigProvider } from "antd";
import EN from "antd/lib/locale/en_US";
import SV from "antd/lib/locale/sv_SE";
import { ENV_NAME } from "configs/env";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { validateMessages } from "utils/validateMessages";
import store from "../../redux/store";
import "./index.scss";
import RootRouter from "./RootRouter";

// @ts-ignore
library.add(fas, far, fal);

Sentry.init({
  dsn: "https://6cca8a8d6c254a19a041f54dde0480f4@o56339.ingest.sentry.io/5870974",
  integrations: [new Integrations.BrowserTracing()],
  environment: ENV_NAME,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App = () => {
  const { t, i18n } = useTranslation();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider
          form={{ validateMessages: validateMessages(t) }}
          locale={i18n.language === "en" ? EN : SV}
        >
          <RootRouter />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
