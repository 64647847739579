import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import React from "react";
import { useSelector } from "react-redux";
const { Text } = Typography;
interface SNCurrencyProps extends Omit<TextProps, "snCurrency"> {
  currency?: string;
  value?: number | string;
  decimal?: number;
  fixed?: boolean;
  min?: number;
  max?: number;
  minus?: boolean;
}

function SNCurrency(props: SNCurrencyProps) {
  const { value, currency, decimal = 2, fixed, min, max, minus, ...newProps } = props;
  const comSettings = useSelector((state: any) => state.comSetting);

  const getValue = (val: any) => {
    if (!val) return "0.00";

    if (minus) return `- ${parseFloat(`${value}`).toFixed(decimal)}`;

    if (min && parseFloat(val) <= min) {
      return min;
    }
    if (max && parseFloat(val) > max) {
      return max;
    }
    if (fixed) return parseFloat(`${value}`);

    if (decimal) return parseFloat(`${value}`).toFixed(decimal);
  };
  return (
    <Text {...newProps}>
      {getValue(value)} {currency || comSettings?.data?.currency_primary || "SEK"}
    </Text>
  );
}

export default SNCurrency;
