export default (
  <div>
    <p>
      För att du ska kunna göra en felanmälan krävs att du anger (i) för- och
      efternamn samt (ii) e-postadress, nedan gemensamt benämnt som
      användaruppgifterna.
    </p>

    <p>
      Det är helt frivilligt att lämna användaruppgifterna men dessa är
      nödvändiga för att du ska kunna göra en Felanmälan
    </p>

    <p>
      Service Node är ansvarigt för användaruppgifterna. Användaruppgifterna
      samlas in och behandlas enbart i syfte att kunna registrera och
      administrera din anmälan.
    </p>

    <p>
      För avhjälpande av eventuellt fel och för korrekt återkoppling till dig
      kan Användaruppgifterna komma att lämnas ut till Service Nodes kunder
      och/eller anlitade underleverantörer och konsulter.
    </p>

    <p>
      Service Node kommer därutöver inte att lämna ut personuppgifter till
      utomstående annat än i de fall då:
    </p>

    <ol type="i">
      <li>det särskilt överenskommits mellan Service Node och dig</li>

      <li>
        då det inom ramen för ett visst uppdrag är nödvändigt för att tillvarata
        dina rättigheter
      </li>

      <li>
        om det är nödvändigt för att Service Node ska fullgöra lagstadgad
        skyldighet eller efterkomma myndighetsbeslut eller beslut av domstol
      </li>

      <li>
        för det fall att Service Node anlitar en utomstående tjänsteleverantörer
        som utför uppdrag för bolagets räkning. Dessa tjänsteleverantörer är då
        skyldiga att enbart använda dina personuppgifter för Service Nodes
        räkning och åtar sig att skydda dessa. Personuppgifter kommer inte att
        lämnas ut till utomstående i marknadsföringssyfte. Användaruppgifterna
        kan komma att föras utanför EU/EES.
      </li>
    </ol>

    <p>
      Du kan när som helst återkalla sitt samtycke till Service Node hantering
      av användaruppgifterna genom att skriftligen meddela detta till Service
      Node.
    </p>

    <p>
      Du har rätt att gratis efter skriftlig ansökan ställd till Service Node,
      få besked om vilka personuppgifter som bolaget behandlar och hur dessa
      behandlas. Du har också rätt att begära rättelse i fråga om
      personuppgifter som Service Node behandlar om dig samt begära rättelse av
      dessa. En eventuell begäran om sådan rättelse ska framställas skriftligen
      till Service Node. Dina personuppgifter kommer inte att behandlas för
      längre tid än vad som är nödvändigt.
    </p>

    <p>
      Genom att godkänna dessa villkor samtycker du till den behandling av
      Användaruppgifterna som anges ovan.
    </p>

    <p>
      Förfrågningar och information om vår behandling av personuppgifter lämnas
      av på begäran genom kontakt på{" "}
      <a href="mailto:support@servicenode.se" className="link">
        support@servicenode.se
      </a>
    </p>

    <p>
      För närmare information om gällande lagstiftning, vilket ansvar Service
      Node har för behandlingen och vilka rättigheter du har, se <br />
      <a
        href="http://www.datainspektionen.se/dataskyddsreformen/"
        className="link"
      >
        http://www.datainspektionen.se/dataskyddsreformen/
      </a>
    </p>
  </div>
);
