import Text, { TextProps } from "antd/lib/typography/Text";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface SNTranslateProps extends TextProps {
  text: string;
}

const SNTranslate: FC<SNTranslateProps> = ({ text, ...props }) => {
  const { t } = useTranslation();

  return <Text {...props}>{t(text)}</Text>;
};

export default SNTranslate;
