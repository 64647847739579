import { Button, ButtonProps, Form } from "antd";
import { FormItemProps, RuleObject } from "antd/lib/form";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

interface SNFormItemProps extends FormItemProps {
  type?: "checkbox" | "input" | "select" | "textarea";
  noTranslateLabel?: boolean;
  label?: any;
  required?: boolean;
  rules?: RuleObject[] | any;
  labelRight?: any;
  rightButton?: ButtonProps;
}

const SNFormItem: FC<SNFormItemProps> = (props) => {
  const {
    label,
    required,
    rules,
    type,
    className = "sn-form-item",
    labelRight,
    rightButton,
    noTranslateLabel,
    ...newProps
  } = props;
  const { t } = useTranslation();

  let rulesItem = rules || [];
  if (!!rules?.find((item: any) => item.type === "number")) {
    rulesItem = [
      ({ getFieldValue }: any) => ({
        validator(rule: any, value: any) {
          if (!value || (value && !isNaN(value))) {
            return Promise.resolve();
          }

          return Promise.reject(
            t("common_form_validation_number_invalid_number")
          );
        },
      }),
    ];
  }
  const itemLabel = () => {
    if (label && rightButton)
      return (
        <div className="sn-form-item-label-has-right">
          {typeof label === "string" ? t(label) : label}{" "}
          <div className="pull-right">
            <Button
              {...rightButton}
              type="link"
              icon="plus-circle"
              title="common_form_add_new_item_label"
            />
          </div>
        </div>
      );
    if (labelRight)
      return (
        <div className="sn-form-item-label-has-right">
          {label && typeof label === "string" ? t(label) : label}{" "}
          <div className="pull-right">{labelRight}</div>
        </div>
      );

    return typeof label === "string" ? t(label) : label;
  };
  return (
    <Form.Item
      label={itemLabel()}
      rules={[{ required: required }, ...rulesItem]}
      className={`${
        type === "checkbox" && "sn-checkbox-input-item"
      } ${className}`}
      {...newProps}
    >
      {props.children}
    </Form.Item>
  );
};

export default SNFormItem;
