import { Empty, EmptyProps, Typography } from "antd";
import React, { CSSProperties, FC } from "react";

const { Text } = Typography;

interface SNEmptyProps extends EmptyProps {
  extraHeight?: number;
  title: string;
  image?: React.ReactNode;
  containerStyle?: CSSProperties;
}

const SNEmpty: FC<SNEmptyProps> = (props) => {
  const { extraHeight = 45, title, containerStyle, ...rest } = props;

  return (
    <div
      style={{
        ...{
          height: `calc(100vh - ${extraHeight}px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 30,
        },
        ...containerStyle,
      }}
    >
      <span>
        <Empty
          description={
            <Text style={{ fontSize: "15px", textAlign: "center" }} type="secondary">
              {title}
            </Text>
          }
          {...rest}
        />
      </span>
    </div>
  );
};

export default SNEmpty;
