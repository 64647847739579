import {
  Avatar,
  Card,
  Col,
  Descriptions,
  Divider,
  Result,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import SNCurrency from "components/SNCurrency";
import SNHeader from "components/SNHeader";
import SNIcon from "components/SNIcon";
import SNLanguage from "components/SNLanguage";
import SNProcessLoader from "components/SNProcessLoader";
import SNTranslate from "components/SNTranslate";
import useMobileDetect from "hooks/useMobileDetect";
import { PARTS_UNIT } from "models/part";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { cleanObject } from "utils";
import logoWhite from "../../assets/images/logo_white.svg";
import "./index.scss";
import { invoiceService } from "./service";

const { Text } = Typography;

const INVOICE_STATUS = {
  1: "system.invoice.status.draft",
  2: "system.invoice.status.issued",
  3: "system.invoice.status.paid",
  4: "system.invoice.status.cancelled",
};

const isValue = (value: any) => {
  if (!value || value === 0 || value === "0" || value === "0.00" || value === "") return false;
  return true;
};

const zoomOutMobile = () => {
  var viewport: any = document.querySelector('meta[name="viewport"]');

  if (viewport) {
    viewport.content = "initial-scale=0.5";
    viewport.content = "width=850";
  }
};

const Invoice: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const printRef = useRef(null);
  const detectMobile = useMobileDetect();

  //Handler

  useEffect(() => {
    if (detectMobile.isMobile()) {
      zoomOutMobile();
    }
  }, [detectMobile]);

  const invoiceID = useMemo(() => {
    return pathname.split("/").filter((data) => !!data)[1];
  }, [pathname]);

  const [invoiceData, error] = invoiceService.useDetails(invoiceID);

  var tempTitle = document.title;
  const _handlePrint = useReactToPrint({
    onBeforePrint: () => {
      document.title = invoiceData?.number || "Invoice";
    },
    onAfterPrint: () => {
      document.title = tempTitle;
    },
    content: () => printRef.current,
  });

  if (!invoiceData && !error) {
    return <SNProcessLoader loading={true} />;
  } else if (error) {
    return <Result status="500" title="500" subTitle={t("common.page_not_found")} />;
  }

  //Render view region

  const header = (
    <SNHeader
      title={`${t("pages.invoice_pdf.header_title")} #${invoiceData?.number}`}
      leftComp={
        <a href="https://servicenode.se">
          <img className="company-logo logo-left" src={logoWhite} alt="Service Node Logo" />
        </a>
      }
      rightComp={
        <Space
          size={[30, 0]}
          style={{ marginRight: !detectMobile.isMobile() ? 30 : 5, marginTop: 3 }}
        >
          <Tooltip placement="bottom" title={t("pages.invoice_pdf.print_btn_tooltip")}>
            <SNIcon
              icon={["fal", "print"]}
              size={"lg"}
              color="#FFF"
              style={{ cursor: "pointer" }}
              onClick={_handlePrint}
            />
          </Tooltip>
          {/* <Tooltip placement="bottom" title={t("pages.invoice_pdf.download_btn_tooltip")}>
            <SNIcon
              icon={["fal", "download"]}
              size={"lg"}
              color="#FFF"
              style={{ cursor: "pointer" }}
              onClick={_handleDownloadPdf}
            />
          </Tooltip> */}
          <SNLanguage />
        </Space>
      }
    />
  );

  /**
   * Invoice view
   */

  const _renderInvoiceHeader = () => {
    if (invoiceData) {
      const {
        company,
        number,
        invoice_date,
        due_date,
        pay_terms,
        ocr_number,
        customer_name,
        customer_number,
        customer_invoice_ref,
        customer_invoice_address,
        our_ref_name,
      } = invoiceData;

      return (
        <div className="invoice-pdf-header">
          <Row gutter={[12, 20]}>
            <Col span={12}>
              <Card.Meta
                avatar={
                  <Avatar
                    icon={<SNIcon icon="building" />}
                    src={company.logo}
                    shape="square"
                    size={42}
                  />
                }
                title={company.company_name}
              />
            </Col>
            <Col span={12}>
              <Descriptions
                size="small"
                title={<SNTranslate text="pages.invoice_pdf.header_invoice_label" />}
                bordered
                column={1}
                className="sn-pdf-description"
              >
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.invoice_number_label" />}
                >
                  {number}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.invoice_date_label" />}
                >
                  {invoice_date}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.invoice_due_date_label" />}
                >
                  {due_date}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={12}>
              <Descriptions size="small" column={1}>
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.customer_number_label" />}
                >
                  {customer_number}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.customer_invoice_ref_label" />}
                >
                  {customer_invoice_ref}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.ocr_number_label" />}
                >
                  {ocr_number}
                </Descriptions.Item>
                <Descriptions.Item label={<SNTranslate text="pages.invoice_pdf.pay_terms_label" />}>
                  NET {pay_terms}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<SNTranslate text="pages.invoice_pdf.our_reference_label" />}
                >
                  {our_ref_name || "-"}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={12}>
              <Descriptions size="small" column={1} title={customer_name}>
                <Descriptions.Item>
                  {customer_invoice_address && (
                    <div style={{ lineHeight: "0.5", fontSize: 13, marginTop: 3 }}>
                      {customer_invoice_address.address2 && (
                        <p>{customer_invoice_address.address2}</p>
                      )}
                      <p>{customer_invoice_address.address}</p>
                      <p>
                        {cleanObject([
                          customer_invoice_address.post,
                          customer_invoice_address.city,
                        ]).join(", ")}
                      </p>
                      {customer_invoice_address.address2 && (
                        <p>{customer_invoice_address.address2}</p>
                      )}
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </div>
      );
    }
    return null;
  };

  const _renderInvoiceItemsTable = () => {
    const data = invoiceData?.invoice_items || [];

    const cols: ColumnsType<any> = [
      {
        key: "item_number",
        dataIndex: "item_number",
        title: <SNTranslate text="pages.invoice_pdf.table_number_label" />,
        width: 165,
      },
      {
        key: "description",
        dataIndex: "description",
        title: <SNTranslate text="pages.invoice_pdf.table_description_label" />,
        render: (val) => <div className="text-description">{val}</div>,
      },
      {
        key: "qty",
        dataIndex: "qty",
        title: <SNTranslate text="pages.invoice_pdf.table_qty_label" />,
        render: (val, row: any) =>
          `${parseFloat(val)}${
            row.item_unit_code
              ? ` ${t(
                  PARTS_UNIT.find((item) => item.value === row.item_unit_code)?.shortLabel || ""
                )}`
              : ""
          }`,
      },
      {
        key: "price",
        dataIndex: "unit_price",
        title: <SNTranslate text="pages.invoice_pdf.table_unit_price_label" />,
      },
      {
        key: "vat_percent",
        dataIndex: "vat_percent",
        title: <SNTranslate text="pages.invoice_pdf.table_vat_label" />,
        render: (val) => {
          return val + "%";
        },
      },
      {
        key: "item_total_excl_vat_amount",
        dataIndex: "item_total_excl_vat_amount",
        title: <SNTranslate text="pages.invoice_pdf.table_total_label" />,
      },
    ];

    return (
      <Table
        pagination={false}
        dataSource={data}
        columns={cols}
        rowKey={(record: any) => record.id}
        className="invoice-pdf-items-table"
      />
    );
  };

  const _renderCalculation = () => {
    if (invoiceData) {
      const {
        description,
        invoice_total_amount,
        invoice_vat_amount,
        invoice_subtotal_excl_vat_amount,
        invoice_fee_amount = 0,
        invoice_rounding_amount = 0,
        currency_code
      } = invoiceData;
      console.log('invoiceData :>> ', invoiceData);
      return (
        <Row gutter={[20, 20]}>
          <Col span={16} style={{ marginTop: 20 }}>
            {description && (
              <div className="text-description">
                <SNTranslate text="pages.invoice_pdf.invoice_description_label" strong />:{" "}
                {description}
              </div>
            )}
          </Col>

          <Col span={8}>
            <ul className="invoice-pdf-sum-container">
              <li key="sum">
                <span className="invoice-pdf-sum-title">
                  <SNTranslate text="pages.invoice_pdf.invoice_sub_total_label" />
                </span>
                <span className="invoice-pdf-sum-value">
                  <SNCurrency value={parseFloat(invoice_subtotal_excl_vat_amount)} currency={currency_code}/>
                </span>
              </li>

              {isValue(invoice_vat_amount) && (
                <li key="vat">
                  <span className="invoice-pdf-sum-title">
                    <SNTranslate text="pages.invoice_pdf.invoice_vat_total_label" />
                  </span>
                  <span className="invoice-pdf-sum-value">
                    <SNCurrency value={invoice_vat_amount} currency={currency_code}/>
                  </span>
                </li>
              )}

              {isValue(invoice_fee_amount) && (
                <li key="invoice-fee">
                  <span className="invoice-pdf-sum-title">
                    <SNTranslate text="pages.invoice_pdf.invoice_fee_amount_label" />
                  </span>
                  <span className="invoice-pdf-sum-value">
                    <SNCurrency value={invoice_fee_amount} currency={currency_code}/>
                  </span>
                </li>
              )}
              {isValue(invoice_rounding_amount) && (
                <li key="invoice-rounding">
                  <span className="invoice-pdf-sum-title">
                    <SNTranslate text="pages.invoice_pdf.invoice_rounding_label" />
                  </span>
                  <span className="invoice-pdf-sum-value">
                    <SNCurrency minus value={invoice_rounding_amount} currency={currency_code}/>
                  </span>
                </li>
              )}

              <Divider style={{ margin: "12px 0" }} />
              <li key="total">
                <span className="invoice-pdf-sum-title">
                  <SNTranslate text="pages.invoice_pdf.invoice_total_label" strong />
                </span>
                <span className="invoice-pdf-sum-value">
                  <SNCurrency value={invoice_total_amount} strong currency={currency_code}/>
                </span>
              </li>
            </ul>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const _renderFooter = () => {
    if (invoiceData) {
      const {
        company_phone,
        website,
        company_email,
        company_name,
        vat_reg_number,
        company_id,
        address,
      } = invoiceData.company;

      return (
        <div className="invoice-pdf-footer keep-together">
          <Card>
            <Row gutter={[40, 16]}>
              <Col span={8}>
                <b>{company_name}</b>
                <Descriptions size="small" column={1}>
                  <Descriptions.Item
                    label={<SNTranslate text="pages.invoice_pdf.footer_company_org_number_label" />}
                  >
                    {company_id}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<SNTranslate text="pages.invoice_pdf.footer_company_vat_number_label" />}
                  >
                    {vat_reg_number}
                  </Descriptions.Item>
                  <Descriptions.Item>
                    {<SNTranslate text="pages.invoice_pdf.footer_company_f_tax_text" />}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8}>
                <SNTranslate strong text="pages.invoice_pdf.footer_contact_label" />

                <Descriptions size="small" column={1}>
                  <Descriptions.Item
                    label={<SNTranslate text="pages.invoice_pdf.footer_phone_label" />}
                  >
                    {company_phone}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<SNTranslate text="pages.invoice_pdf.footer_email_label" />}
                  >
                    {company_email}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<SNTranslate text="pages.invoice_pdf.footer_website_label" />}
                  >
                    {website}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8}>
                <SNTranslate strong text="pages.invoice_pdf.footer_address_label" />

                {address && (
                  <div style={{ lineHeight: "0.5", fontSize: 13, marginTop: 6 }}>
                    {address.address2 && <p>{address.address2}</p>}
                    <p>{address.address}</p>
                    <p>{cleanObject([address.post, address.city]).join(", ")}</p>
                    {address.address2 && <p>{address.address2}</p>}
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </div>
      );
    }
    return null;
  };

  const _renderOverlayStatus = () => {
    if (invoiceData && (invoiceData.status === 3 || invoiceData.status === 4)) {
      const { status, is_cancelled } = invoiceData;

      return (
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Text
            style={{
              color: "#ff9191",
              fontSize: 100,
              opacity: 0.5,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t(is_cancelled ? INVOICE_STATUS[4] : INVOICE_STATUS[status]).toUpperCase()}
          </Text>
        </div>
      );
    }

    return null;
  };

  const content = (
    <Space align="start" style={{ width: "100%" }}>
      <div className="invoice-pdf-content">
        <div id="print-pdf" className="invoice-pdf-content-container" ref={printRef}>
          {_renderInvoiceHeader()}
          {_renderInvoiceItemsTable()}
          {_renderCalculation()}
          {_renderFooter()}
          {_renderOverlayStatus()}
        </div>
      </div>
    </Space>
  );

  ////////////////////////////////////

  return (
    <div className="invoice-root">
      {header}
      <div className="invoice-container">
        <div className="invoice-content">
          <div style={{ height: "auto" }}>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
