import { ArrowLeftOutlined, SettingOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Menu, Space, Typography } from "antd";
import SNOwnerInfoModal from "components/SNOwnerInfoModal";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "redux/reducers";
import "./index.scss";

const { Title, Text } = Typography;

interface SNHeaderProps {
  title: string;
  back?: boolean;
  rightComp?: React.ReactNode;
  leftComp?: React.ReactNode;
  rightMenu?: React.ReactNode[];
}

const SNHeader: FC<SNHeaderProps> = (props) => {
  const { title, back = false, rightComp, leftComp, rightMenu } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { nodeData } = useSelector((state: RootState) => state.node);

  const [showOwnerModal, setShowOwnerModal] = useState<boolean>(false);

  return (
    <div className="sn-header">
      <div className="sn-header-content">
        {leftComp}
        {back && !leftComp ? (
          <Space size={[14, 0]} className="sn-header-left">
            <ArrowLeftOutlined
              style={{ fontSize: "23px", color: "#FFF" }}
              onClick={() => history.goBack()}
            />
          </Space>
        ) : null}
        <div className="ant-col">
          <Title level={5} className="sn-header-title">
            {title}
          </Title>
        </div>
        <Space size={[14, 0]} className="sn-header-right">
          {rightComp || (
            <div>
              <Dropdown
                overlay={
                  <Menu>
                    {!rightMenu ? (
                      <Menu.Item key={1} onClick={() => setShowOwnerModal(true)}>
                        {t("common.components.sn_header.option_contact_owner")}
                      </Menu.Item>
                    ) : (
                      rightMenu
                    )}
                    <Divider style={{ marginTop: 2, marginBottom: 2 }} />
                    <Menu.Item
                      key={2}
                      onClick={() => {
                        i18n.changeLanguage("sv");
                        moment.locale("sv");
                      }}
                      style={{
                        backgroundColor: i18n.language === "sv" ? "#42a5f5" : "#FFF",
                      }}
                    >
                      <Space role="img" aria-label={"🇸🇪"}>
                        {"🇸🇪"}
                        <Text
                          style={{
                            color: i18n.language === "sv" ? "#FFF" : undefined,
                          }}
                        >
                          {t("common.components.sn_language.swedish_item")}
                        </Text>
                      </Space>
                    </Menu.Item>
                    <Menu.Item
                      key={3}
                      onClick={() => {
                        i18n.changeLanguage("en");
                        moment.locale("en");
                      }}
                      style={{
                        backgroundColor: i18n.language === "en" ? "#42a5f5" : "#FFF",
                      }}
                    >
                      <Space role="img" aria-label={"🇺🇸"}>
                        {"🇺🇸"}
                        <Text
                          style={{
                            color: i18n.language === "en" ? "#FFF" : undefined,
                          }}
                        >
                          {t("common.components.sn_language.english_item")}
                        </Text>
                      </Space>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <SettingOutlined style={{ fontSize: "18px", color: "#FFF", marginTop: "5px" }} />
              </Dropdown>
              {!rightMenu && (
                <SNOwnerInfoModal
                  visible={showOwnerModal}
                  onCancel={() => setShowOwnerModal(false)}
                  onOk={() => setShowOwnerModal(false)}
                  data={nodeData}
                />
              )}
            </div>
          )}
        </Space>
      </div>
    </div>
  );
};

export default SNHeader;
